<template>
    <div class="vendor-app-fee">
        <md-card>
            <md-card-header>
                <h3>Vendor App Fee</h3>
            </md-card-header>
            <md-card-content>
                <md-input-container>
                    <label for>Vendor</label>
                    <span class="val" v-if="vendor">{{vendor.name}} {{vendor.email}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Total</label>
                    <span class="val">{{total | currency}}</span>
                    <md-input type="hidden" v-model="total"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>UnPaid</label>
                    <strong class="val">{{unpaid | currency}}</strong>
                    <md-input type="hidden" v-model="unpaid"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>UnRefund</label>
                    <strong class="val">{{unrefund | currency}}</strong>
                    <md-input type="hidden" v-model="unrefund"></md-input>
                </md-input-container>
                <!-- <md-input-container>
                    <label for>Invoicing</label>
                    <strong class="val">{{invoicing | currency}}</strong>
                    <md-input type="hidden" v-model="invoicing"></md-input>
                </md-input-container> -->
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { country } from "@/config";

export default {
    props: ["id"],
    components: {},
    data() {
        return {
            vendor: null,
            total: 0,
            unpaid: 0,
            unrefund: 0,
            invoicing: 0
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        })
    },
    methods: {
        async loadVendor() {
            if (this.id) {
                let result = await this.$store.dispatch("crud/get", {
                    api: `users/${this.id}`
                });
                this.vendor = result;
            } else {
                this.vendor = this.user;
            }
        },
        async loadVendorAppFee() {
            let result = await this.$store.dispatch("crud/get", {
                api: `pays/appfee/${this.vendor._id}`
            });
            this.total = result.total;
            this.unpaid = result.unpaid;
            this.unrefund = result.unrefund;
            this.invoicing = result.invoicing;
        }
    },
    async created() {
        await this.loadVendor();
        await this.loadVendorAppFee();
    }
};
</script>

<style lang="less">
@import "../../mixins.less";
.vendor-app-fee {
    .md-card {
        max-width: 1000px;
        margin: auto;
    }
    .form;
}
</style>
